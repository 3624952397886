<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->

        
        <h1 class="titles">
          <span style="color: #8b8c8c">Juego de pelota</span>
        </h1>
        <br>
<b-row align-h="center">
              <b-col cols="11" md="8" lg="5" xl="5"  class="mb-5" style="height:380px;">
                  <div style="height:100%; width: 100%;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:380px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/ojZmQs8RBXI"
                    ></b-embed>
                  </div>
                </b-col>
                
              </b-row>
              <br><br>
        
        <h1 class="titles">
          <span style="color: #8b8c8c">Grupos de Danza Prehispánica</span>
        </h1>

        <br><br>

                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/danzap.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/6%20danzantes/danza1.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/6%20danzantes/danza2.JPG"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>


                    <br><br>

    <b-row  align-h="center" class="mt-4" v-for="(item, idx) in datos " :key="idx">
        <b-col cols="10" sm="8" md="5" lg="4">
          <b-row><h5><b>{{item.nombre}} {{item.direccion}}</b></h5></b-row>
          <b-row><h6><b-icon icon="telephone-fill"/> {{item.telefono}}</h6></b-row>
        </b-col>
    </b-row>




    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
      
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
            {img:'99',nombre:'Danza Prehispánica ',direccion:'Maribel ',telefono:'5532035871',url:'',tipo:''},
{img:'99',nombre:'Danza prehispánica ',direccion:'Nahui',telefono:'5521984254',url:'',tipo:''},
{img:'99',nombre:'Danza Prehispánica ',direccion:'Candelario',telefono:'5528956836',url:'',tipo:''},
{img:'99',nombre:'Danza Prehispánica ',direccion:'Odilon',telefono:'5536639379',url:'',tipo:''},
{img:'99',nombre:'Danza Prehispánica ',direccion:'Michell',telefono:'5535023551',url:'',tipo:''},


        ]
      }
    }
    
  }
</script>